<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref } from 'vue'
import { mask } from 'vue-the-mask'
import { useRoute, useRouter } from 'vue-router'
import { showDefaultQuotas, updateDefaultQuotas } from './api'
import Notify from '@/notify'

const highlightOptionMap = {
  0: {
    label: 'Não',
    class: 'badge-soft-danger'
  },
  1: {
    label: 'Sim',
    class: 'badge-soft-success'
  }
}

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const contestDefaultQuota = ref({
      value: '',
      hihglight_option: 0,
      created_at: '',
      updated_at: ''
    })

    const submitDefaultQuotasForm = async () => {
      try {
        await updateDefaultQuotas(route.params.id, contestDefaultQuota.value)
        Notify.create({
          title: 'Dados salvos com sucesso!',
          timer: 1500
        })
        router.push({ name: 'contest_default_quotas_list' })
      } catch (e) {
        Notify.create({
          title: 'Ocorreu um erro ao salvar as informações',
          timer: 1500
        })
      }
    }

    onMounted(async () => {
      if (route.params.id) {
        const data = await showDefaultQuotas(route.params.id)
        contestDefaultQuota.value = data
      }
    })

    return {
      route,
      contestDefaultQuota,
      highlightOptionMap,
      submitDefaultQuotasForm
    }
  },
  components: {
    Layout
  },
  directives: {
    mask
  }
}
</script>

<template>
  <Layout pagetitle="Editar Cota Padrão de Sorteio">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Editar Cota Padrão de Sorteio
            </h5>
          </div>
          <div class="p-4 border-top">
            <form
              class="was-validated"
              @submit.prevent="submitDefaultQuotasForm"
            >
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="title"
                      class="form-label"
                    >
                      Valor
                    </label>
                    <input
                      id="title"
                      v-model="contestDefaultQuota.value"
                      class="form-control"
                      placeholder="Valor padrão de cota"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="hightligh_option"
                      class="form-label"
                    >
                      Destacar
                    </label>
                    <select
                      v-model="contestDefaultQuota.highlight_option"
                      class="form-select"
                      required
                    >
                      <option
                        v-for="(highlightOption, key) in highlightOptionMap"
                        :key="key"
                        :value="key"
                      >
                        {{ highlightOption.label }}
                      </option>
                    </select>
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-end">
                <router-link
                  :to="{ name: 'contest_default_quotas_list' }"
                  class="btn btn-secondary me-2"
                >
                  <i class="bx bx-left-arrow-circle me-1" />
                  Cancelar
                </router-link>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Salvar alterações
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
