import { axios } from '@/axios'

export const getDefaultQuotas = (params = {}) => {
  return axios.get('/admin/contest-default-quotas', { params }).then(({ data }) => data)
}

export const showDefaultQuotas = (defaultQuotasId) => {
  return axios.get(`/admin/contest-default-quotas/${defaultQuotasId}`).then(({ data }) => data)
}

export const updateDefaultQuotas = (defaultQuotasId, params = {}) => {
  return axios.put(`/admin/contest-default-quotas/${defaultQuotasId}`, params).then(({ data }) => data)
}
